import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { Locale } from '../../../../../translations/models.translations';
import {Constants} from "../../../../../constants/Constants";

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
  @Input() src = 'assets/images/common/logo-yesport.svg';

  isUkLocale = this.locale === Constants.DEFAULT_LOCALE;

  constructor(@Inject('LOCALE') public locale: Locale) {
  }

}
