import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Locale } from '../../../translations/models.translations';
import { Menu } from '../models/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private _menu$: Observable<Menu[]> | undefined;
  status$ = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient,
              @Inject(DOCUMENT) private document: Document,
              @Inject('LOCALE') private locale: Locale,) {
    this.status$.subscribe(status => {
      status
        ? this.document.body.classList.add('disable-scroll')
        : this.document.body.classList.remove('disable-scroll');
    });
  }

  setStatus(status: boolean) {
    this.status$.next(status);
  }

   getMenu(): Observable<Menu[]> {
     const params = new HttpParams().set('menuKey', 'MAIN_MENU');
     if (!this._menu$) this._menu$ = this.http.get<Menu[]>(`${environment.apiBase}/menus/menu`,  { params }).pipe(shareReplay(1));
     return this._menu$;
   }
}

