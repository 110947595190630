<div class="social-links-block">
  <a class="social-link" href="https://www.facebook.com/profile.php?id=100095375269603" target="_blank">
    <img ngSrc="assets/images/social/facebook.png" height="60" width="60" alt="facebook">
  </a>
  <a class="social-link" href="https://www.instagram.com/yesport_ua" target="_blank">
    <img ngSrc="assets/images/social/instagram.png" height="60" width="60" alt="instagram">
  </a>
<!--  <a class="social-link" href="#" target="_blank">-->
<!--    <img ngSrc="assets/images/social/threads.png" height="60" width="60">-->
<!--  </a>-->
</div>
