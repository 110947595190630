import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Constants, LayoutColors } from '../../../../../constants/Constants';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent {
  @Input() color: LayoutColors = Constants.colors['divider-color'];
  @HostBinding('style.background-color') get backgroundColor(): string {
    return this.color;
  }
}
