<footer class="footer-container">
  <div class="col wrapper">
    <section class="row adv-info">
      <div class="col-lg-5">
        <div class="footer-big-card">
          <a class="col-lg-2 icon-link" href="mailto:info@yesport.com.ua">
            <img ngSrc="assets/images/contacts/mail_snail.png" fill alt="mail snail"></a>
          <div class="col">
            <h2 class="info-title">{{CommonTranslations['editorialTeam'] | translation}}</h2>
            <a class="adv-info-link" href="mailto:info@yesport.com.ua">info&#64;yesport.com.ua</a>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row footer-big-card">
          <a class="col-lg-2 icon-link" href="mailto:adv@yesport.com.ua">
            <img ngSrc="assets/images/contacts/mail_arrow.png" fill alt="mail arrow">
          </a>
          <div class="col">
            <h2 class="info-title">{{CommonTranslations['advDepartment'] | translation}}</h2>
            <a class="adv-info-link" href="mailto:adv@yesport.com.ua">adv&#64;yesport.com.ua</a>
          </div>
        </div>
      </div>
    </section>
    <section class="row navigation-menu">
      <div class="col-lg-5 nav-col">
        <h2 class="info-title">{{CommonTranslations['categories'] | translation}}</h2>
        @if(categoriesLinks$ | async; as menuLinks){
          <nav class="nav-block links">
            @for(link of menuLinks; track link){
              <a class="nav-link" [href]="link.url">{{link.text}}</a>
            }
          </nav>
        }
      </div>
      <div class="col-lg-7 nav-col">
        <h2 class="info-title">{{CommonTranslations['links'] | translation}}</h2>
        <nav class="nav-block categories">
          @for(item of staticPagesLinks; track item){
            <a class="nav-link" [href]="item.link">{{item.name}}</a>
          }
        </nav>
      </div>
    </section>
    <app-divider class="footer-divider"></app-divider>
    <section class="row logo-social">
      <app-logo class="footer-logo"></app-logo>
      <app-socials-block></app-socials-block>
    </section>
  </div>

</footer>

