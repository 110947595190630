import {TranslationGroup} from './models.translations';

export const CommonTranslations: TranslationGroup = {
  all: {
    uk: 'усі',
    ru: 'все',
  },
  allNews: {
    uk: 'усі новини',
    ru: 'все новости',
  },
  noResults: {
    uk: 'Немає результатів :(',
    ru: 'Нет результатов :(',
  },
  moreNews: {
    uk: 'більше новин',
    ru: 'больше новостей',
  },
  comments: {
    uk: 'коментарі',
    ru: 'комментарии',
  },
  socialNetwork: {
    uk: 'Соцмережі',
    ru: 'Соцсети',
  },

  facebook: {
    uk: 'фейсбук',
    ru: 'фейсбук'
  },
  twitter: {
    uk: 'твітер',
    ru: 'твиттер'
  },
  instagram: {
    uk: 'інстаграм',
    ru: 'инстаграм',
  },
  programs: {
    uk: 'програми',
    ru: 'программы',
  },
  subscribe: {
    uk: 'підписатися',
    ru: 'подписаться',
  },
  editorialTeam: {
    uk: 'Редакція',
    ru: 'Редакция',
  },
  contacts: {
    uk: 'Контакти',
    ru: 'Контакты'
  },
  address: {
    uk: 'Адреса',
    ru: 'Адрес'
  },
  advDepartment: {
    uk: 'Рекламний відділ',
    ru: 'Рекламный отдел'
  },
  phoneNumber: {
    uk: 'номер телефону',
    ru: 'номер телефона'
  },
  studioAddress: {
    uk: 'м. Київ, вул. Володимирська, 61',
    ru: 'г. Киев, ул. Владимирская, 61'
  },
  aboutUs: {
    uk: 'Про нас',
    ru: 'О нас'
  },
  aboutUsLong: {
    uk: 'Про нас',
    ru: 'О нас'
  },
  termsOfUse: {
    uk: 'Правила користування',
    ru: 'Правила пользования'
  },
  termsOfUseLong: {
    uk: 'Правила користування сайтом',
    ru: 'Правила пользования сайтом'
  },
  privacyPolicy: {
    uk: 'Політика конфіденційності',
    ru: 'Политика конфиденциальности'
  },
  advertisers: {
    uk: 'Рекламодавцям',
    ru: 'Рекламодателям'
  },
  editorialPrinciples: {
    uk: 'Редакційна політика',
    ru: 'Редакционная политика'
  },
  categories: {
    uk: 'Категорії',
    ru: 'Категории'
  },
  links: {
    uk: 'Посилання',
    ru: 'Ссылки'
  },

};
