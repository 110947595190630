import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DividerComponent} from "../../ui/elements/divider/divider.component";
import {LogoComponent} from "../../ui/elements/logo/logo.component";
import {SocialsBlockComponent} from "./blocks/socials-block/socials-block.component";
import { CommonTranslations } from '../../../../translations/common.translations';
import {TranslationPipe} from "../../pipes/translation.pipe";
import {MenuService} from "../../services/menu.service";
import {Constants} from "../../../../constants/Constants";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, DividerComponent, LogoComponent, SocialsBlockComponent, TranslationPipe],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {

  protected readonly CommonTranslations = CommonTranslations;
  categoriesLinks$ = this.menuService.getMenu();
  staticPagesLinks : {name: string, link: string}[] = [];

  constructor(private menuService: MenuService,
  @Inject('LOCALE') protected locale: string) {
    this.staticPagesLinks = locale === 'ru' ? Constants.footerStaticLinks.ru : Constants.footerStaticLinks.uk;
  }

}

