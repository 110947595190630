import { ChangeDetectionStrategy, Component } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-socials-block',
  standalone: true,
    imports: [CommonModule, NgOptimizedImage],
  templateUrl: './socials-block.component.html',
  styleUrl: './socials-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialsBlockComponent {

}
